export function connectWebsocket(movePosition) {
    const url = 'ws://127.0.0.1:3000/'
//const url = 'ws://192.168.178.40:3000/'

    const socket = new WebSocket(url);

    socket.onclose = function () {
        console.log('socket closed')
    };
    socket.onopen = ()=>console.log("socket opened")
    socket.onmessage = function (message) {
        let data = JSON.parse(message.data)
        console.log(data)
        switch (data.type) {
            case 'ping':
                socket.send("pong")
                break
            case 'deebot':
                handleDeebotData(movePosition, data)
                break
        }
    }
}


//coordinates
//const on_charger = {x: 0, y: 0}
const on_charger = {x: 5.312128067016602, y:  -0.6935989260673523}

const handleDeebotData = (movePosition, data) => {
    switch (data.name) {
        case 'position':
            const positionData = data.data.split(',')
            const relativePosition = {x: positionData[0] - on_charger.x, z: positionData[1] - on_charger.y}
            movePosition(relativePosition, positionData[2])
            console.log("relativePosition: ", relativePosition, data)
            break
    }
}

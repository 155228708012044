import './style.css'
import * as dat from 'lil-gui'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js'
import {Box3} from "three";
import gsap from 'gsap'
import {connectWebsocket} from "./websocket";


/**
 * Base
 */
// Debug
const gui = new dat.GUI({
    width: 400
})


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Object
 */
const bakedTexture = textureLoader.load('buero_backed.jpg')
bakedTexture.flipY = false
//bakedTexture.encoding = THREE.sRGBEncoding
const bakedMaterial = new THREE.MeshBasicMaterial({
    map: bakedTexture
})
const roomTexture = textureLoader.load('back.jpg')
roomTexture.flipY = false
const roomMaterial = new THREE.MeshBasicMaterial({map: roomTexture})
gltfLoader.load(
    'room_merged.glb',
    (gltf) => {
        gltf.scene.traverse((child) => {
            child.material = roomMaterial
        })
        scene.add(gltf.scene)
        camera.lookAt(gltf.scene.position)
        console.log(gltf.scene.position)

    }
)
gltfLoader.load(
    'buero.glb',
    (gltf) => {
        gltf.scene.traverse((child) => {
            child.material = bakedMaterial
        })
        scene.add(gltf.scene)
    }
)

let robbi = undefined
gltfLoader.load(
    'robbi.glb',
    (gltf) => {
        gltf.scene.traverse((child) => {
            child.material = bakedMaterial
        })
        gltf.scene.position.y = 1.02
        //   gltf.scene.position.set(2.5, 1.02, 1.7)
        scene.add(gltf.scene)
        robbi = gltf.scene.children[0]
    }
)
gltfLoader.load(
    'robbi_station.glb',
    (gltf) => {
        gltf.scene.traverse((child) => {
            child.material = bakedMaterial
        })
        gltf.scene.position.y = 1.02
        console.log(gltf.scene)
        scene.add(gltf.scene)
    }
)
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 4
scene.add(camera)

gui.add(camera.position, 'x', -5, 5, .001)
gui.add(camera.position, 'y', -5, 5, .001)
gui.add(camera.position, 'z', -5, 5, .001)

// Controls
const controls = new OrbitControls(camera, canvas)
/*controls.enableDamping = true
controls.enableZoom = false
controls.minPolarAngle = 0; // radians
controls.maxPolarAngle = 2; // radians*/

gui.add(controls.target, 'x', -5, 5, .001)
gui.add(controls.target, 'y', -5, 5, .001)
gui.add(controls.target, 'z', -5, 5, .001)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

const box = new THREE.BoxGeometry(.2, .1, .2)
//const box = new THREE.BoxGeometry(.4, .1, .4)
const mat = new THREE.MeshBasicMaterial({color: 'red'})
const m = new THREE.Mesh(box, mat)
m.position.set(2.5, .8, 1.7)
//scene.add(m)

const clock = new THREE.Clock()
const cameraLookInOffice = () => {
    gsap.to(controls.target, {x: -3.5, y: 1, z: 2.7, duration: 2})
    gsap.to(camera.position, {x: -5, y: 1.552, z: 1.812, duration: 2})
}
const cameraLookInKitchen = () => {
    gsap.to(controls.target, {x: 1.232, y: .972, z: 2.471, duration: 2})
    gsap.to(camera.position, {x: 2.667, y: 1.102, z: 1.428, duration: 2})
}

const parameters = {
    color: 0xff0000,
    lookOffice: () => cameraLookInOffice(),
    lookKitchen: () => cameraLookInKitchen()
}
gui.add(parameters, 'lookOffice')
gui.add(parameters, 'lookKitchen')
//setTimeout(()=>cameraLookInOffice(),2000)


let robbiCleanRoute = []
const points = [];
points.push(new THREE.Vector3(-10, 2, 0));
points.push(new THREE.Vector3(0, 10, 0));
points.push(new THREE.Vector3(10, 3, 0));

//draw robbiCleanRoute
const MAX_POINTS = 500
let drawCount
let line
const cleanRouteGeometry = new THREE.BufferGeometry
const cleanRoutePosition = new Float32Array(MAX_POINTS * 3)
const cleanRouteMaterial = new THREE.LineBasicMaterial({color: 'red', lineWidth: 2})
cleanRouteGeometry.setAttribute('position', new THREE.BufferAttribute(cleanRoutePosition, 3))

line = new THREE.Line(cleanRouteGeometry, cleanRouteMaterial)


function updatePositions() {

    const positions = line.geometry.attributes.position.array;

    for (let i = 0, l = robbiCleanRoute.length; i < l; i++) {

        positions[i] = robbiCleanRoute[i][0];
        positions[i] = robbiCleanRoute[i][1];
        positions[i] = robbiCleanRoute[i][2];

    }

}

//draw calls
drawCount = 2
cleanRouteGeometry.setDrawRange(0, drawCount)

scene.add(line)
console.log("line:", line)
const tick = () => {


    drawCount = (drawCount + 1) % MAX_POINTS
   // line.geometry.setDrawRange(0,drawCount)

    //updatePositions()
    //line.geometry.attributes.position.needsUpdate = true
    const elapsedTime = clock.getElapsedTime()
    // Update controls
    points.push(new THREE.Vector3(14, 3, 2));

    line.updateMatrix()
    controls.update()
    // Render

    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}


tick()
const guiRobbi = gui.addFolder('Robbi')

setTimeout(() => {
    guiRobbi.add(robbi.position, 'x', -10, 10, .001)
    guiRobbi.add(robbi.position, 'z', -10, 10, .001)
}, 1000)
const moveDeebot = (position, rotation) => {

    // if(!robbi)return

    // const offset = {x: 5.2, y: .35, z: .5 - m.geometry.parameters.width}
    // const offset = {x: 0, y: 0, z: 0}
    const offset = {x: 5.2, y: .35, z: .5 - .27}

    //DeebotPosition:  -10379,-2804,7
    position = {
        x: position.x / 1000,
        z: position.z / -1600
    }
    /* position = {
        x: position.x / 1000,
        z: position.z / -1600
    }*/

    robbi.rotation.set(0, rotation / 180 * Math.PI, 0)
    // TODO gsap.to(controls.target, {x: 1.232, y: .972, z: 2.471, duration: 2})
    //   m.position.set(position.x + offset.x, m.position.y, position.z + offset.z)
    robbi.position.set(position.x + offset.x, robbi.position.y, position.z + offset.z)
    robbiCleanRoute.push([position.x + offset.x, robbi.position.y, position.z + offset.z])

 // gsap.to(robbi.position,{...position,y:robbi.position.y, duration: .5})
}


let position = {x: -10379, z: -2804.7}
//setInterval(() => callIt(), 900)
const callIt = () => {
    // position = {x:2.5,z:1.7}
    //position = {x: position.x + (Math.random() - .5) * 200, z: position.z + (Math.random() - .5) * 200}
    moveDeebot(position, 0)

}
connectWebsocket(moveDeebot)


